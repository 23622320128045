@import '../../assets/styles/variables.module.scss';
@import '../../assets//styles/mixins.module.scss';

.container {
    padding: 0rem;
    height: 92vh;
    min-height: 92vh;
    overflow: hidden;
}

.main {
    width: 100%;
    height: 100%;
    @include flex-column-start-start;
    gap: 0.8rem;
    
    position: relative;
    background-color: white;
    padding: 1rem 0.8rem;


    // border: 1px solid red;
}

.main_top {
    width: 100%;
    height: auto;

    @include flex-row-between-center;
    overflow: hidden;
}
.go_back_img{
    width: 2rem;
    height: 2rem;
}
.data_picker_div{
    position: absolute;
    left: 50%;

    transform: translate(-50%);
    z-index: 1000;
}



.main_middle {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    
    border-radius: 0.5rem;
    border: 1px solid $input_background_shade;

    padding: 0rem;
}
.table_div{
    width: 100%;
    height: 100%;

    overflow-x: scroll;
    overflow-y: scroll;

    & thead th {
        padding: 0.8rem !important;
        background-color: white;
        z-index: 1;
    }
}

.table_data {
    & tr:hover {
        background-color: $input_background_shade;
    }

    tr:nth-child(even) {
        background-color: $input_background_shade_tr;
    }

    & td:hover{
        background-color: #e2eaff;
    }
}


.dropdown{
    width: 100%;
    margin: 0rem !important;

    border: 1px solid $input_background_shade;
    padding: 0.5rem;
    margin-top: 1rem;
    border-radius: 4px;

    & select{
        width: 100%;
        border: none;
        outline: none;
    }
}


.search_div{
    width: 5rem !important;

}
.search_icon{
    width: 100%;
    border-radius: 4px;

    padding: 0rem 0.3rem;
    margin-bottom: 0.5rem;

    @include flex-row-start-center;
    background-color: $input_background;
    
    & input{
        width: 100%;
        padding: 0.5rem 0rem;
        padding-right: 0.5rem;
        border: none;
        outline: none;
        background-color: $input_background;
    }
}
.sort_icon{
    @include sort_icon;
    box-shadow: 0px 0px 10px #c8d6fc !important;
}
.sort_icon_asc{
    @include sort_icon;
    box-shadow: 0px 0px 10px orange !important;
    rotate: 180deg;
}
.sort_icon_desc{
    @include sort_icon;
    box-shadow: 0px 0px 10px green !important;
}




.main_bottom {
    width: 100%;
    height: auto;
    padding: 0rem 0rem !important;
    @include flex-row-between-center;
    gap: 0.5rem;

    // border: 1px solid red;
}


.main_top_extra_padding {
    @include flex-row-between-center;
    width: 100%;
    margin: 1rem 0rem;
    @include gray-bottom-border;
}


.main_title {
    @include page-title
}

.main_title_secondary{
    @include flex-row-center-center;
    @include page-title-secondary;

}

.items_selected_div{
    // font-size: 0.9rem;
    font-weight: bold;
    color: $gray-primary;
}


.main_search {
    @include flex-row-between-center;
    width: 50%;
    gap: 1rem;
}

.main_operation {
    @include flex-row-between-center;
    gap: 1rem;
}


.filter {
    display: flex;
    gap: 1rem;
}

.filter_checkboxes {
    display: flex;
    gap: 1rem;
    border-style: solid;
    border-width: 2px;
    border-color: $blue-primary;
    padding: 0rem 2rem;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.filter_checkboxes_item {
    // font-size: 0.9rem;
    font-weight: bolddd;
    color: $blue-primary;
    display: flex;
    align-items: center;
    gap: 0.5rem;
}


.go_back_btn_div{
    cursor: pointer;
    text-decoration: underline;
}

.go_back_btn_div:hover{
    color: $red-primary;
    cursor: pointer;
    text-decoration: underline;
}

.go_back_btn{
    padding: 0rem 0.5rem;
}



.td_underline{
    text-decoration: underline !important;
    cursor: pointer;
    font-weight: bold;
}

.td_underline:hover{
    color: $red-primary;
}


.action_tab_buttons{
    @include flex-row-center-center;
    gap:3rem;
    height: auto;
    width: 100%;
}


.input_date{
    background-color: #f3f2f2;
    color: $color-foreshop;
    @include gray-bottom-border;
    padding:1rem;

    border-radius: 0.5rem;
    border-style: solid;
    border-color: rgb(211, 211, 211);
    border-width: 1px;
}

.input_date:focus{
    border-color: rgb(211, 211, 211);

}

.action_button_each{
    cursor: pointer;
}

.action_button_each_inactive{
    cursor: progress;
}

.action_button_each_send_now{
    cursor: pointer;
    width: 12%;
    height: auto;
}

.action_button_each_send_now_sending{
    cursor: progress;
    width: 12%;
    height: auto;
}

.action_button_each_website{
    cursor: pointer;
    padding: 0rem .5rem;
    margin: 0rem 1rem
}

.no_data_found_div{
    @include flex-row-center-center;
    // font-size: 0.9rem;
    font-weight: bold;
    color: $red-primary;
    margin: 1rem;
    padding: 1rem;
}


a:link {
    color: $blue-primary;
    text-decoration: none;
  }
  
  a:visited {
    color: $blue-primary;;
    text-decoration: none;
  }
  
  a:hover {
    color: $red-primary;;
    text-decoration: none;
  }





// ######################################################## designs for website control ########################################################
.formAlertDiv{
    color: $red-primary;
  }
  

.creative_image_section{
    width: 100%;
}

.favicon_icon{
    width: 40%;
}




// ######################################################## designs for automation tab ########################################################
.funnel_tier_div{
    @include flex-row-start-start;
    height: 100%;
}


.automation_card{
    @include flex-column-start-start;
    background-color: #f3f2f2;
    border-radius: 1rem;
    padding: 1rem;
}

.daterange_selection {
    margin-right: 1em;
    margin-top: 0.5em;
    text-align: center;
    border: 1px solid #d4d4d4;
    padding: 0.5em;
    border-radius: 7px;
    background: #f6f6f6;
    display: flex;
    cursor: pointer;
}
.daterange_selection_date{
    color: #080247;
}



@media only screen and (max-width: 767px) {
    .search_icon{
        min-width: 10rem;
    }
}


@media only screen and (max-width: 575px) {
    .main_top {
        width: 100%;
        height: auto;

        @include flex-row-between-center;
        overflow: hidden;


        & div{
            // border: 1px solid red;
        }
    }
}